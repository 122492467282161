import React from 'react';
import Layout from 'components/layout';

const ContactSuccess = () => (
  <Layout>
    <div className="row spacious">
      <h4>Thanks for getting in touch!</h4>
      <p>I will do my best in getting back to you as soon as I can.</p>
      <p>
        <a className="button" href="/">
          Go back
        </a>
      </p>
    </div>
  </Layout>
);

export default ContactSuccess;
